import React, { createContext, useContext, useState, useMemo } from 'react';

import useDeepCompareRef from 'Hooks/useDeepCompareRef';

const HitCardPopupContext = createContext();

export const HitCardPopupProvider = ({ children }) => {
  const [hit, setHit] = useState(null);
  const [position, setPosition] = useState({ top: 0, left: 0 });
  const [config, setConfig] = useState(null);

  const showPopup = (product, options) => {
    setHit(product);
    setConfig(options);
  };
  const hidePopup = () => setHit(null);
  const setPopupPosition = coordinates => setPosition(coordinates);

  const stableHit = useDeepCompareRef(hit);
  const stablePosition = useDeepCompareRef(position);
  const stableConfig = useDeepCompareRef(config);

  const contextValue = useMemo(() => ({
    isOpen: !!stableHit?.slug,
    hit: stableHit,
    position: stablePosition,
    config: stableConfig,
    showPopup,
    hidePopup,
    setPopupPosition,
  }), [stableHit, stablePosition, stableConfig]);

  return (
    <HitCardPopupContext.Provider
      value={contextValue}
    >
      {children}
    </HitCardPopupContext.Provider>
  );
};

export const useHitCardPopup = () => useContext(HitCardPopupContext);
