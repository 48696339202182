import React from 'react';

import env from 'Env';

import 'Components/Cards/HitCardPopup/HitCardPopupCriticScore.scss';

const HitCardPopupCriticScore = ({
  metaCriticScore = 0,
  openCriticScore = 0,
  // metaCriticScore = 89,
  // openCriticScore = 0,
}) => {
  let reviewScore = 0;
  const recommendedThreshold = env.percentRecommendedThreshold;

  let reviewImgLink = 'https://cdn.fanatical.com/production/brand/metacritic/svg/metacritic-icon.svg';
  let reviewImgAlt = 'Metacritic';

  if (metaCriticScore >= recommendedThreshold) {
    reviewScore = metaCriticScore;
  } else if (openCriticScore >= recommendedThreshold) {
    reviewImgLink = 'https://cdn.fanatical.com/production/brand/opencritic/svg/opencritic-icon.svg';
    reviewImgAlt = 'OpenCritic';
    reviewScore = openCriticScore;
  }

  const showCriticScore = reviewScore >= recommendedThreshold;
  if (!showCriticScore) return false;

  return (
    <div
      className="HitCardPopupCriticScore"
    >
      <div className="HitCardPopupCriticScore__score">
        {reviewScore}
      </div>
      <div className="HitCardPopupCriticScore__logo">
        <img
          src={reviewImgLink}
          width="100px"
          alt={reviewImgAlt}
        />
      </div>
    </div>
  );
};

export default HitCardPopupCriticScore;
