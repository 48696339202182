import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import { DropdownItem } from 'reactstrap';

import DropdownMenu from 'Components/Utility/DropdownMenu';
import ButtonDropdown from 'Components/Utility/ButtonDropdown';
import DropdownToggle from 'Components/Utility/DropdownToggle';
import {
  toggleGiftModal,
  toggleLoginModal,
} from 'Redux/ducks/modal';
import store from 'Redux/store';
import Price from 'Containers/Utility/Price/Price';
import { currencyCode } from 'Helpers/Geo/data';

const HitCardTieredGiftBtn = ({
  displayName,
  hit,
  authenticated,
  wishlistId,
  emailConfirmed,
  authWishlistId,
  bundleTiers,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const onGiftClick = (tierIndex) => {
    if (!authenticated) {
      return store.dispatch(toggleLoginModal());
    }

    if (wishlistId) {
      return store.dispatch(toggleGiftModal({
        giftOrder: {},
        giftAction: 'wishlist-item',
        displayName,
        item: hit,
        wishlistId,
        tierIndex,
        emailConfirmed,
      }));
    }

    return false;
  };

  let skaItem = false;
  let validDrm = '';
  if (hit.drm && hit.drm.length === 1) validDrm = hit.drm[0];

  const skaArray = ['origin', 'epicgames', 'uplay', 'threeds', 'switch'];

  if (skaArray.includes(validDrm)) {
    skaItem = true;
  }

  if (skaItem || (authWishlistId === wishlistId) || hit.giveaway || hit.presale) return false; // eslint-disable-line

  return (
    <ButtonDropdown isOpen={isOpen} toggle={() => setIsOpen(!isOpen)}>
      <DropdownToggle
        caret
        color="primary"
        className="btn btn-primary tiered-gift-button"
      >
        <FontAwesomeIcon icon="gift" className="mr-1" />
        <FormattedMessage id="hitcardtieredgiftbtn.view" defaultMessage="Gift" />
      </DropdownToggle>

      {!hit.mystery && hit.display_type !== 'comic-bundle' &&
        <DropdownMenu>
          {bundleTiers.map((tier, tierIndex) =>
            <DropdownItem
              key={tier._id + Math.random()}
              onClick={() => onGiftClick(tierIndex)}
            >
              <FormattedMessage
                id="hitcardtieredgiftbtn.tierpricetotal"
                defaultMessage="Tier {tierNumber}: {price} {totalProducts, plural, one {# Product} other {# Products}}"
                values={{
                  tierNumber: tierIndex + 1,
                  price: <Price price={{ [currencyCode]: (tier.price[currencyCode] * 100) }} />,
                  totalProducts: tier.displayTotal,
                }}
              />
            </DropdownItem>,
          )}
        </DropdownMenu>
      }

      {!hit.mystery && hit.display_type === 'comic-bundle' &&
        <DropdownMenu>
          {bundleTiers.map((tier, tierIndex) =>
            <DropdownItem
              key={tier._id + Math.random()}
              onClick={() => onGiftClick(tierIndex)}
            >
              <FormattedMessage
                id="hitcardtieredgiftbtn.tierpricetotalcomics"
                defaultMessage="Tier {tierNumber}: {price} {totalProducts, plural, one {# Digital Comic} other {# Digital Comics}}"
                values={{
                  tierNumber: tierIndex + 1,
                  price: <Price price={{ [currencyCode]: (tier.price[currencyCode] * 100) }} />,
                  totalProducts: tier.downloadTotal,
                }}
              />
            </DropdownItem>,
          )}
        </DropdownMenu>
      }

      {hit.mystery &&
        <DropdownMenu>
          {bundleTiers.map((tier, tierIndex) =>
            <DropdownItem
              key={tier._id + Math.random()}
              onClick={() => onGiftClick(tierIndex)}
            >
              <FormattedMessage
                id="hitcardtieredgiftbtn.tierpricetotalmystery"
                defaultMessage="{tierNumber, plural, one {# Key} other {# Keys}} - {price}"
                values={{
                  tierNumber: tierIndex + 1,
                  price: <Price price={{ [currencyCode]: (tier.price[currencyCode] * 100) }} />,
                }}
              />
            </DropdownItem>,
          )}
        </DropdownMenu>
      }
    </ButtonDropdown>
  );
};

HitCardTieredGiftBtn.propTypes = {
  displayName: PropTypes.string,
  hit: PropTypes.shape({}),
  authenticated: PropTypes.bool,
  wishlistId: PropTypes.string,
  emailConfirmed: PropTypes.bool,
  authWishlistId: PropTypes.string,
  bundleTiers: PropTypes.arrayOf(PropTypes.object),
};

HitCardTieredGiftBtn.defaultProps = {
  displayName: '',
  hit: {},
  authenticated: false,
  wishlistId: '',
  emailConfirmed: false,
  authWishlistId: '',
  bundleTiers: [],
};

const mapStateToProps = ({
  auth: { authenticated, email_confirmed: emailConfirmed, wishlist_id: authWishlistId },
}) => ({
  authenticated,
  emailConfirmed,
  authWishlistId,
});

const mapDispatchToProps = {
  toggleLoginModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(HitCardTieredGiftBtn);
