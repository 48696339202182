import React, { useRef } from 'react';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';

import 'Components/Cards/HitCardPopup/HitCardPopupScreenshotCarousel.scss';
import CarouselPaging from 'Components/Carousel/CarouselPaging';
import CarouselArrow from 'Components/Carousel/CarouselArrow';
import FluidImg from 'Components/Utility/FluidImg';


const HitCardPopupScreenshotCarousel = ({
  screenshots: screenshotsProp = [],
  displayType,
  type,
  linkPath = '',
  linkFunc = () => { },
}) => {
  const sliderRef = useRef();
  if (screenshotsProp?.length === 0) return false;

  const isBundle = displayType === 'comic-bundle' || displayType === 'book-bundle' || displayType === 'elearning-bundle' || displayType === 'software-bundle' || displayType === 'audio-bundle' || type === 'bundle';
  // const tallScreenshots = displayType === 'comic-bundle' || displayType === 'book-bundle' || displayType === 'elearning-bundle' || displayType === 'software-bundle' || displayType === 'audio-bundle';
  const isPromotion = (type === 'pick-and-mix');

  let screenshots = screenshotsProp.slice(0, 6);
  let screenshotLength = screenshots.length || 0;

  if (isBundle || isPromotion) {
    screenshots = screenshotsProp.slice(0, 5);
    screenshotLength = ((screenshots.length || 0) + 1);
  }

  const settings = {
    infinite: true,
    prevArrow: <CarouselArrow ariaLabel="Previous" arrowDirection="left" containerClassName="arrow-btn-container" />,
    nextArrow: <CarouselArrow ariaLabel="Next" arrowDirection="right" containerClassName="arrow-btn-container" />,
    slidesToShow: 1,
    slidesToScroll: 1,
    accessibility: true,
    customPaging: i => (
      <CarouselPaging
        slideIndex={i}
        slideLength={Array.isArray(screenshots) ? screenshots.length : 0}
        slidesToShow={1}
      />
    ),
    lazyLoad: false,
  };

  return (
    <div className="responsive-image-island HitcardPopupScreenshotCarousel">
      <div className="responsive-image responsive-image--16by9">
        <Slider ref={sliderRef} {...settings} className="HitcardPopupScreenshotCarousel__slider">
          {screenshotLength >= 1 && screenshots.map(cover =>
            <div className="screenshot-image faux-block-link faux-block-link" key={cover + 1}>
              <Link to={linkPath} className="faux-block-link__overlay-link" onClick={linkFunc} />
              <FluidImg src={cover} alt={cover} />
            </div>,
          )}
        </Slider>
      </div>
    </div>
  );
};

export default HitCardPopupScreenshotCarousel;
