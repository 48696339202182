import React, { useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import differenceInDays from 'date-fns/difference_in_days';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import ProductCover from 'Components/Utility/ProductCover';
import HitCardLabel from 'Components/Cards/HitCardLabel';
import HitCardWishlistBtn from 'Components/Cards/HitCardWishlistBtn';
import HitCardPopupCriticScore from 'Components/Cards/HitCardPopup/HitCardPopupCriticScore';
import StarRating from 'Components/ProductReviews/UserReviews/StarRating';
import Drm from 'Components/Utility/Drm';
import DlcBadge from 'Components/Cards/DlcBadge';
import AddToCartBtn from 'Components/Cards/AddToCartBtn';
import HitLinkTo from 'Helpers/Product/HitLinkTo';
import PriceContainer from 'Containers/Utility/Price/PriceContainer';
import { toggleCheckoutModal } from 'Redux/ducks/modal';
import NewCountdownTimer from 'Components/Utility/CountdownTimer/CountdownTimer';
import { useHitCardPopup } from 'Context/HitCardPopupContext';
import HitCardPopupScreenshotCarousel from 'Components/Cards/HitCardPopup/HitCardPopupScreenshotCarousel';

import 'Components/Cards/HitCardPopup/HitCardPopup.scss';

const HitCardPopup = () => {
  const dispatch = useDispatch();
  const HitCardPopupRef = useRef();
  const authenticated = useSelector(state => state?.auth?.authenticated);
  const userLibrary = useSelector(state => state?.userLibrary);
  const steamLibrary = useSelector(state => state?.steamLibrary);

  const { hit, position, hidePopup, config } = useHitCardPopup();

  if (!hit || !position) return null;

  const {
    isBigCard = false,
    productCoverSizes,
    adaptiveSize = false,
    coverType = '',
    flashDeal = false,
    showFlashDealBar = false,
    notAlgolia = false,
    hitCardLightTheme,
    clickFunction = () => { },
    place,
  } = config || {};

  const {
    slug,
    name = '',
    type,
    // categories = ['Action', 'Simulation', 'Strategy'],
    categories = [],
    cover = '',
    portrait_cover: portraitCover = '',
    display_type: displayType = '',
    cover_alt_text: coverAltText = '',
    presale,
    features = [],
    free_to_play: freeToPlay = false,
    // rating = 4,
    rating_score: rating,
    opencritic_score: openCriticScore,
    metacritic_score: metaCriticScore,
    sdesc,
    platforms,
    is_srp_bundle: isSrpBundle = false,
    price,
    discount_percent: discountPercent,
    screenshots,
  } = hit;


  const inLibrary = userLibrary.includes(hit.name);
  const inSteamLibrary = steamLibrary.includes(hit.name);
  const lowerCaseFeatures = features.map(feature => feature.toLowerCase());
  const isPromotion = type === 'pick-and-mix';
  const freeToPlayTag = lowerCaseFeatures.includes('free to play');

  let startingDate = hit.available_valid_from;
  let endingDate = hit.available_valid_until;
  let OS = hit.operating_systems || [];

  let validDrm = '';
  let filteredDrm = [];
  if (hit && hit.drm && !flashDeal && !notAlgolia) filteredDrm = hit.drm.filter(d => d !== 'voucher' && d !== 'drm free');
  if (filteredDrm.length === 1) validDrm = filteredDrm[0];
  if (flashDeal || notAlgolia) {
    const validPlatforms = Object.keys(hit.drm).filter(d => hit.drm[d]);
    filteredDrm = validPlatforms.filter(platform => platform !== 'voucher');
    validDrm = filteredDrm[0];
    startingDate = hit.available_valid_from / 1000;
    endingDate = hit.available_valid_until / 1000;

    OS = [];
    if (platforms && platforms.windows) OS.push('windows');
    if (platforms && platforms.mac) OS.push('mac');
    if (platforms && platforms.linux) OS.push('linux');
  }

  let cardType = 'standard';
  if (hit.display_type === 'comic-bundle' || hit.display_type === 'comic') cardType = 'comic';
  if (hit.display_type === 'book-bundle' || hit.display_type === 'book') cardType = 'book';
  if (hit.display_type === 'elearning-bundle' || hit.display_type === 'elearning') cardType = 'elearning';
  if (hit.display_type === 'audio-bundle' || hit.display_type === 'audio') cardType = 'audio';
  if (hit.display_type === 'software-bundle' || hit.display_type === 'software') cardType = 'software';
  if (hit.display_type === 'graphic-novel') cardType = 'graphic-novel';
  const daysLeft = differenceInDays(new Date(endingDate * 1000), new Date());

  const showTimer = !isSrpBundle && (hit.type === 'bundle' || hit.type === 'pick-and-mix') && daysLeft < 90;

  const onLinkClick = () => {
    if (place === 'Mega Menu') {
      clickFunction();
    }

    if (place === 'Checkout Modal') {
      dispatch(toggleCheckoutModal());
    }

    hidePopup();
  };

  let showAddButton = false;
  if (!hit.tiered && !hit.presale && !hit.free_to_play && !hit.pay_what_you_want && hit.type !== 'pick-and-mix') {
    showAddButton = true;
  }


  const discountPercentOverride = hit.discount_percent_override;

  const onMouseLeave = () => {
    hidePopup();
  };

  let _labelOverride = config?.labelOverride;

  if (!_labelOverride) {
    if (inLibrary && authenticated && !hit.mystery) {
      _labelOverride = 'inLibrary';
    } else if (inSteamLibrary && authenticated && !hit.mystery) {
      _labelOverride = 'inSteamLibrary';
    }
  }

  return (
    <div
      ref={HitCardPopupRef}
      className={
        classNames('HitCardPopup  fadeInAnimation', {
          'HitCardPopup--isBigCard': isBigCard,
        })
      }
      style={{
        top: position.top,
        left: position.left,
      }}
      key={slug}
      onMouseLeave={onMouseLeave}
    >
      <HitCardLabel
        isPopup
        slug={hit.slug}
        bestDiscount={hit.best_ever}
        preorderDate={hit.release_date}
        startingDate={startingDate}
        endingDate={endingDate}
        type={hit.type}
        displayType={hit.display_type}
        presale={hit.presale}
        discountPercent={hit.discount_percent}
        flashDealCard={flashDeal || showFlashDealBar}
        noReleaseDate={hit.no_release_date}
        collections={hit.collections || []}
        systemDiscount={hit.system_discount}
        labelOverride={_labelOverride}
        earlyAccessDate={hit.preorder_playable_from}
        isStarDeal={hit.star_deal}
      />

      {(!screenshots || screenshots?.length === 0) &&
        <div className="HitCardPopup__cover">
          <ProductCover
            lazy
            cover={cover}
            portraitCover={portraitCover}
            alt={coverAltText || `Product cover for ${name}`}
            sizesOverride={productCoverSizes}
            displayType={displayType}
            coverType={coverType}
            adaptiveSize={adaptiveSize}
          />
        </div>
      }

      {screenshots?.length > 0 &&
        <HitCardPopupScreenshotCarousel
          screenshots={screenshots}
          displayType={displayType}
          type={type}
          linkPath={HitLinkTo(hit)}
          linkFunc={onLinkClick}
        />
      }

      <div className="HitCardPopup__content faux-block-link">
        <div className="HitCardPopup__content__titleButton">
          <Link to={HitLinkTo(hit)} className="HitCardPopup__title faux-block-link__overlay-link" onClick={onLinkClick}>
            {name}
          </Link>
          <div className="HitCardPopup__wishlistButtonContainer">
            {presale && (!freeToPlayTag || freeToPlay) && !isPromotion &&
              <HitCardWishlistBtn
                productId={hit.product_id}
                slug={hit.slug} hit={hit}

              />
            }
            {!presale && !isPromotion &&
              <HitCardWishlistBtn
                productId={hit.product_id}
                slug={hit.slug} hit={hit}
              />
            }

          </div>
        </div>

        <div className="HitCardPopup__content__reviews">
          {rating && rating >= 4 &&
            <div className="HitCardPopup__content__stars">
              <StarRating rating={rating} />
            </div>
          }

          <HitCardPopupCriticScore
            openCriticScore={openCriticScore}
            metaCriticScore={metaCriticScore}
          />

        </div>

        {categories?.length > 0 &&
          <div className="tag-column-container">
            {categories.slice(0, 3).map(tag =>
              <div className="tag-column" key={tag}>
                <div className="tag">
                  {tag}
                </div>
              </div>,
            )}
          </div>
        }

        <div className="HitCardPopup__content__shortDesc">
          {sdesc}
        </div>

        <div className="hitCardStripe__content__icons HitCardPopUp__DrmPriceContainer">
          <div className="HitCardPopUp__DrmContainer">
            {(validDrm || cardType === 'standard') &&
              <React.Fragment>
                {validDrm &&
                  <Drm drmType={validDrm} colorOverride={hitCardLightTheme ? 'dark' : ''} />
                }
                {hit.display_type === 'dlc' &&
                  <DlcBadge />
                }
                {(!isEmpty(OS) || validDrm === 'utalk') &&
                  <div className="hitCardStripe__content__icons__os">
                    {includes(OS, 'windows') &&
                      <FontAwesomeIcon icon={['fab', 'windows']} size="lg" />
                    }
                    {includes(OS, 'mac') &&
                      <FontAwesomeIcon icon={['fab', 'apple']} size="lg" />
                    }
                    {includes(OS, 'linux') &&
                      <FontAwesomeIcon icon={['fab', 'linux']} size="lg" />
                    }
                    {validDrm === 'utalk' &&
                      <FontAwesomeIcon icon={['fab', 'android']} size="lg" />
                    }
                  </div>
                }
              </React.Fragment>
            }
            {!validDrm && (cardType === 'comic' || cardType === 'book' || cardType === 'elearning' || cardType === 'audio' || cardType === 'software' || cardType === 'graphic-novel') &&
              <div className="hitCardStripe__content__icons__nonGame">
                {cardType === 'comic' &&
                  <>
                    <FontAwesomeIcon icon="book-open" />
                    <FormattedMessage id="hitcardpopup.comic-bundle" defaultMessage="Comic" />
                  </>
                }
                {cardType === 'book' &&
                  <>
                    <FontAwesomeIcon icon="book-open" />
                    <FormattedMessage id="hitcardpopup.book-bundle" defaultMessage="eBook" />
                  </>
                }
                {cardType === 'elearning' &&
                  <>
                    <FontAwesomeIcon icon="graduation-cap" />
                    <FormattedMessage id="hitcardpopup.elearning-bundle" defaultMessage="eLearning" />
                  </>
                }
                {cardType === 'audio' &&
                  <>
                    <FontAwesomeIcon icon="headphones-alt" />
                    <FormattedMessage id="hitcardpopup.audio" defaultMessage="Audio" />
                  </>
                }
                {cardType === 'software' &&
                  <>
                    <FontAwesomeIcon icon="laptop-code" />
                    <FormattedMessage id="hitcardpopup.software" defaultMessage="Software" />
                  </>
                }
                {cardType === 'graphic-novel' &&
                  <>
                    <FontAwesomeIcon icon="book-open" />
                    <FormattedMessage id="hitcardpopup.graphic-novel" defaultMessage="Graphic Novel" />
                  </>
                }
              </div>
            }
          </div>

          <div className="hitCardStripe__content__purchase">
            {!showTimer &&
              <Link to={HitLinkTo(hit)} className="hitCardStripe__content__purchase__price faux-block-link__overlay-link" onClick={onLinkClick}>
                {hit.presale && (!freeToPlayTag || hit.free_to_play) &&
                  <div className="hitCardStripe__content__purchase__price__text">
                    <FormattedMessage id="hitcardpop.availablesoon" defaultMessage="Available Soon" />
                  </div>
                }

                {(hit.free_to_play || (hit.presale && freeToPlayTag)) &&
                  <div className="hitCardStripe__content__purchase__price__text">
                    <FormattedMessage id="hitcardpop.freetoplay" defaultMessage="Free to Play" />
                  </div>
                }

                {!hit.presale && !hit.free_to_play && !hit.hide_price &&
                  <PriceContainer
                    discountPercent={discountPercent}
                    discountPercentOverride={discountPercentOverride}
                    drm={validDrm}
                    fullPrice={hit.fullPrice}
                    giftButton={false}
                    hideDiscount={hit.hide_discount}
                    lightTheme={hitCardLightTheme}
                    mystery={hit.mystery}
                    payWhatYouWant={hit.pay_what_you_want}
                    price={price}
                    showAsterisk={!!hit.addVoucher}
                    slug={hit.slug}
                    tiered={hit.tiered}
                    type={hit.type}
                  />
                }
              </Link>
            }

            {showTimer &&
              <Link to={HitLinkTo(hit)} className="faux-block-link__overlay-link" onClick={onLinkClick}>
                <NewCountdownTimer
                  cardTimer
                  value={endingDate * 1000}
                />
              </Link>
            }

            {showAddButton &&
              <AddToCartBtn
                newIcon
                showInCartLink
                id={hit.product_id}
                slug={hit.slug}
                voucherToAdd={hit.addVoucher}
              />
            }
          </div>
        </div>
      </div>
    </div >
  );
};

export default HitCardPopup;
