import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import includes from 'lodash/includes';
import get from 'lodash/get';
import classNames from 'classnames';
import { connect } from 'react-redux';
import differenceInDays from 'date-fns/difference_in_days';
import { Col } from 'reactstrap';

// import { countryCode } from 'Helpers/Geo/data';
import AddToCartBtn from 'Components/Cards/AddToCartBtn';
import store from 'Redux/store';
import DlcBadge from 'Components/Cards/DlcBadge';
import ProductCover from 'Components/Utility/ProductCover';
// import HitCardButtons from 'Components/Cards/HitCardButtons';
import HitCardInCart from 'Components/Cards/HitCardInCart';
import HitCardLabel from 'Components/Cards/HitCardLabel';
import Drm from 'Components/Utility/Drm';
import PriceContainer from 'Containers/Utility/Price/PriceContainer';
import FlashCardProgress from 'Components/FlashSales/FlashCardProgress';
import { toggleCheckoutModal } from 'Redux/ducks/modal';
import FormatProductName from 'Helpers/Format/FormatProductName';
import HitLinkTo from 'Helpers/Product/HitLinkTo';
import useViewport from 'Hooks/useViewport';
import imageSizes from 'Components/Cards/image-sizes';
import NewCountdownTimer from 'Components/Utility/CountdownTimer/CountdownTimer';
import { useHitCardPopup } from 'Context/HitCardPopupContext';

function PopupHitCard({
  hit,
  siteTheme,
  lgSize,
  mdSize,
  smSize,
  xsSize,
  bigCard,
  notAlgolia,
  flashDeal,
  showFlashDealBar, // Allows regular hits to show bar as the flashDeal prop changes data
  hitCardLightTheme,
  hitCardDarkTheme,
  lazy,
  hideDesc,
  classes,
  sizesOverride,
  place,
  giftButton,
  // displayName,
  // wishlistId,
  // wishlistSlugs,
  // inCart,
  labelOverride,
  hitCardContainerTag,
  // hitCardNameTag,
  clickFunction,
  inLibrary,
  inSteamLibrary,
  authenticated,
  coverTypeOverride,
  adaptiveSize,
}) {
  const cardRef = useRef(null);
  const { isAboveSm, isBelowLg } = useViewport();
  const { showPopup, setPopupPosition } = useHitCardPopup();
  const { locale } = window;
  let startingDate = hit.available_valid_from;
  let endingDate = hit.available_valid_until;
  let OS = hit.operating_systems || [];
  const discountPercentOverride = hit.discount_percent_override;
  const hoverTimeout = useRef(null);
  const isBigCard = lgSize === 4;

  const isSrpBundle = get(hit, 'is_srp_bundle', false);
  const sdescs = get(hit, 'sdescs', {});
  const features = get(hit, 'features', []);
  const lowerCaseFeatures = features.map(feature => feature.toLowerCase());
  const platforms = hit.platforms;

  let freeToPlayTag = false;
  if (includes(lowerCaseFeatures, 'free to play')) {
    freeToPlayTag = true;
  }

  // let noGermanRelease = false;
  // if (countryCode === 'DE' && hit.no_release_date) {
  //   noGermanRelease = true;
  // }

  // const isPromotion = (hit.type === 'pick-and-mix');

  const onLinkClick = () => {
    if (place === 'Mega Menu') {
      clickFunction();
    }

    if (place === 'Checkout Modal') {
      store.dispatch(toggleCheckoutModal());
    }
  };

  let price = hit.price;
  let validDrm = '';
  let filteredDrm = [];
  if (hit && hit.drm && !flashDeal && !notAlgolia) filteredDrm = hit.drm.filter(d => d !== 'voucher' && d !== 'drm free');
  if (filteredDrm.length === 1) validDrm = filteredDrm[0];
  if (flashDeal || notAlgolia) {
    const validPlatforms = Object.keys(hit.drm).filter(d => hit.drm[d]);
    filteredDrm = validPlatforms.filter(platform => platform !== 'voucher');
    validDrm = filteredDrm[0];

    startingDate = hit.available_valid_from / 1000;
    endingDate = hit.available_valid_until / 1000;

    OS = [];
    if (platforms && platforms.windows) OS.push('windows');
    if (platforms && platforms.mac) OS.push('mac');
    if (platforms && platforms.linux) OS.push('linux');
  }

  if (notAlgolia) {
    const formattedPrice = {};
    Object.keys(price).forEach((currency) => {
      if (!formattedPrice[currency]) {
        formattedPrice[currency] = price[currency] / 100;
      }
    });
    price = formattedPrice;
  }

  let discountPercent = hit.discount_percent;

  if (hit.type === 'pick-and-mix') {
    discountPercent = hit.pnm_saving;
  }

  OS.sort().reverse();

  const name = FormatProductName(hit.name, hit.type);

  // const applyHideOverlay = !wishlistSlugs.includes(hit.slug)
  //   || inCart;

  let cardTheme = 'dark';
  if (hitCardDarkTheme) cardTheme = 'dark';
  if (hitCardLightTheme || siteTheme === 'light') cardTheme = 'light';

  // const TagName = hitCardNameTag;

  let productCoverSizes = imageSizes(lgSize, mdSize, smSize, xsSize);

  if (sizesOverride) productCoverSizes = sizesOverride;

  let cardType = 'standard';
  if (hit.display_type === 'comic-bundle' || hit.display_type === 'comic') cardType = 'comic';
  if (hit.display_type === 'book-bundle' || hit.display_type === 'book') cardType = 'book';
  if (hit.display_type === 'elearning-bundle' || hit.display_type === 'elearning') cardType = 'elearning';
  if (hit.display_type === 'audio-bundle' || hit.display_type === 'audio') cardType = 'audio';
  if (hit.display_type === 'software-bundle' || hit.display_type === 'software') cardType = 'software';
  if (hit.display_type === 'graphic-novel') cardType = 'graphic-novel';

  let showAddButton = false;
  if (!hit.tiered && !hit.presale && !hit.free_to_play && !hit.pay_what_you_want && hit.type !== 'pick-and-mix') {
    showAddButton = true;
  }

  let coverType = '';
  if (hit.cover_type) coverType = hit.cover_type;
  if (coverTypeOverride) coverType = coverTypeOverride;

  // <Link to={HitLinkTo(hit)} className="hitCardStripe__content__purchase__price faux-block-link__overlay-link" onClick={onLinkClick}></Link>

  let _labelOverride = labelOverride;

  if (!_labelOverride) {
    if (inLibrary && authenticated && !hit.mystery) {
      _labelOverride = 'inLibrary';
    } else if (inSteamLibrary && authenticated && !hit.mystery) {
      _labelOverride = 'inSteamLibrary';
    }
  }

  const daysLeft = differenceInDays(new Date(endingDate * 1000), new Date());
  const showTimer = !isSrpBundle && (hit.type === 'bundle' || hit.type === 'pick-and-mix') && daysLeft < 90;

  const handleHover = () => {
    const cardRect = cardRef?.current?.getBoundingClientRect();
    let top = cardRect.top + window.scrollY + cardRect.height / 2;
    let left = cardRect.left + window.scrollX + cardRect.width / 2;
    left -= 200;
    top -= 121;

    if (isBigCard) {
      left -= 45;
      top -= 40;
    }

    setPopupPosition({ top: `${top}px`, left: `${left}px` });
  };


  const handleHoverStart = (e) => {
    clearTimeout(hoverTimeout.current);
    hoverTimeout.current = setTimeout(() => {
      showPopup(hit, {
        isBigCard,
        labelOverride,
        productCoverSizes,
        coverType,
        flashDeal,
        showFlashDealBar,
        hitCardLightTheme,
        clickFunction,
        place,
        adaptiveSize,
      });
      handleHover(e);
    }, 200);
  };

  const handleHoverEnd = () => {
    clearTimeout(hoverTimeout.current);
    hoverTimeout.current = setTimeout(() => {
    }, 200);
  };
  return (
    <React.Fragment>
      <Col
        tag={hitCardContainerTag}
        lg={lgSize}
        md={mdSize}
        sm={smSize}
        xs={xsSize}
        className={classNames(
          'HitCardContainer',
          { classes: classes !== '' },
        )}
      >
        <div
          ref={cardRef}
          className={classNames(
            'HitCard HitCard--popup',
            { 'HitCard--dark': cardTheme === 'dark' },
            { 'HitCard--light': cardTheme === 'light' },
            { 'HitCard--light': cardTheme === 'light' },
            { 'big-hit-card': bigCard },
            { 'flash-hit-card': flashDeal || showFlashDealBar },
            'faux-block-link',
          )}
          onMouseEnter={handleHoverStart}
          onMouseLeave={handleHoverEnd}
        >
          <HitCardLabel
            slug={hit.slug}
            bestDiscount={hit.best_ever}
            preorderDate={hit.release_date}
            startingDate={startingDate}
            endingDate={endingDate}
            type={hit.type}
            displayType={hit.display_type}
            presale={hit.presale}
            discountPercent={hit.discount_percent}
            flashDealCard={flashDeal || showFlashDealBar}
            noReleaseDate={hit.no_release_date}
            collections={hit.collections || []}
            systemDiscount={hit.system_discount}
            labelOverride={_labelOverride}
            earlyAccessDate={hit.preorder_playable_from}
            isStarDeal={hit.star_deal}
          />


          <div key={hit.product_id} className="HitCard__main">
            <HitCardInCart slug={hit.slug} />
            <ProductCover
              cover={hit.cover}
              portraitCover={hit.portrait_cover}
              alt={hit.cover_alt_text || `Product cover for ${name}`}
              sizesOverride={productCoverSizes}
              displayType={hit.display_type}
              coverType={coverType}
              lazy={lazy || place === 'Mega Menu'}
              adaptiveSize={adaptiveSize}
            />

            <div className="hitCardStripe">
              {bigCard && !hideDesc &&
                <div className="big-card-sdesc d-flex align-items-center">
                  {sdescs[locale] || hit.sdesc}
                </div>
              }

              <div className="hitCardStripe__content">
                <div className="hitCardStripe__content__purchase">
                  {!showTimer &&
                    <Link to={HitLinkTo(hit)} className="hitCardStripe__content__purchase__price faux-block-link__overlay-link" onClick={onLinkClick}>
                      {hit.presale && (!freeToPlayTag || hit.free_to_play) &&
                        <div className="hitCardStripe__content__purchase__price__text">
                          <FormattedMessage id="popuphitcard.availablesoon" defaultMessage="Available Soon" />
                        </div>
                      }

                      {(hit.free_to_play || (hit.presale && freeToPlayTag)) &&
                        <div className="hitCardStripe__content__purchase__price__text">
                          <FormattedMessage id="popuphitcard.freetoplay" defaultMessage="Free to Play" />
                        </div>
                      }

                      {!hit.presale && !hit.free_to_play && !hit.hide_price &&
                        <PriceContainer
                          discountPercent={discountPercent}
                          discountPercentOverride={discountPercentOverride}
                          drm={validDrm}
                          fullPrice={hit.fullPrice}
                          giftButton={giftButton}
                          hideDiscount={hit.hide_discount}
                          lightTheme={hitCardLightTheme}
                          mystery={hit.mystery}
                          payWhatYouWant={hit.pay_what_you_want}
                          price={price}
                          showAsterisk={!!hit.addVoucher}
                          slug={hit.slug}
                          tiered={hit.tiered}
                          type={hit.type}
                        />
                      }
                    </Link>
                  }

                  {showTimer &&
                    <Link to={HitLinkTo(hit)} className="faux-block-link__overlay-link" onClick={onLinkClick}>
                      <NewCountdownTimer
                        cardTimer
                        value={endingDate * 1000}
                      />
                    </Link>
                  }

                  {showAddButton && isBelowLg &&
                    <AddToCartBtn
                      newIcon
                      showInCartLink
                      hideButtonText
                      id={hit.product_id}
                      slug={hit.slug}
                      voucherToAdd={hit.addVoucher}
                    />
                  }
                </div>

                {isAboveSm &&
                  <div className="hitCardStripe__content__icons">
                    {(validDrm || cardType === 'standard') &&
                      <React.Fragment>
                        {validDrm &&
                          <Drm drmType={validDrm} colorOverride={hitCardLightTheme ? 'dark' : ''} />
                        }
                        {hit.display_type === 'dlc' &&
                          <DlcBadge />
                        }
                      </React.Fragment>
                    }
                    {!validDrm && (cardType === 'comic' || cardType === 'book' || cardType === 'elearning' || cardType === 'audio' || cardType === 'software' || cardType === 'graphic-novel') &&
                      <div className="hitCardStripe__content__icons__nonGame">
                        {cardType === 'comic' &&
                          <>
                            <FontAwesomeIcon icon="book-open" />
                            <FormattedMessage id="popuphitcard.comic-bundle" defaultMessage="Comic" />
                          </>
                        }
                        {cardType === 'book' &&
                          <>
                            <FontAwesomeIcon icon="book-open" />
                            <FormattedMessage id="popuphitcard.book-bundle" defaultMessage="eBook" />
                          </>
                        }
                        {cardType === 'elearning' &&
                          <>
                            <FontAwesomeIcon icon="graduation-cap" />
                            <FormattedMessage id="popuphitcard.elearning-bundle" defaultMessage="eLearning" />
                          </>
                        }
                        {cardType === 'audio' &&
                          <>
                            <FontAwesomeIcon icon="headphones-alt" />
                            <FormattedMessage id="popuphitcard.audio" defaultMessage="Audio" />
                          </>
                        }
                        {cardType === 'software' &&
                          <>
                            <FontAwesomeIcon icon="laptop-code" />
                            <FormattedMessage id="popuphitcard.software" defaultMessage="Software" />
                          </>
                        }
                        {cardType === 'graphic-novel' &&
                          <>
                            <FontAwesomeIcon icon="book-open" />
                            <FormattedMessage id="popuphitcard.graphic-novel" defaultMessage="Graphic Novel" />
                          </>
                        }
                      </div>
                    }
                  </div>
                }
              </div>
            </div>
            {(flashDeal || showFlashDealBar) &&
              <FlashCardProgress startingDate={startingDate} endingDate={endingDate} />
            }
          </div>
        </div>
      </Col>
    </React.Fragment>
  );
}

PopupHitCard.propTypes = {
  bigCard: PropTypes.bool,
  classes: PropTypes.string,
  clickFunction: PropTypes.func,
  // displayName: PropTypes.string,
  flashDeal: PropTypes.bool,
  notAlgolia: PropTypes.bool,
  giftButton: PropTypes.bool,
  hideDesc: PropTypes.bool,
  // inCart: PropTypes.bool,
  inLibrary: PropTypes.bool,
  inSteamLibrary: PropTypes.bool,
  hit: PropTypes.shape({}),
  hitCardContainerTag: PropTypes.string,
  hitCardDarkTheme: PropTypes.bool,
  hitCardLightTheme: PropTypes.bool,
  // hitCardNameTag: PropTypes.string,
  labelOverride: PropTypes.string,
  lgSize: PropTypes.number,
  lazy: PropTypes.bool,
  mdSize: PropTypes.number,
  place: PropTypes.string,
  showFlashDealBar: PropTypes.bool,
  sizesOverride: PropTypes.string,
  smSize: PropTypes.number,
  // wishlistId: PropTypes.string,
  // wishlistSlugs: PropTypes.arrayOf(PropTypes.string),
  xsSize: PropTypes.number,
  authenticated: PropTypes.bool,
  coverTypeOverride: PropTypes.string,
  adaptiveSize: PropTypes.bool,
};

PopupHitCard.defaultProps = {
  bigCard: false,
  classes: '',
  clickFunction: () => { },
  displayName: '',
  flashDeal: false,
  notAlgolia: false,
  giftButton: false,
  hideDesc: false,
  inCart: false,
  inLibrary: false,
  inSteamLibrary: false,
  hit: {},
  hitCardContainerTag: 'div',
  hitCardDarkTheme: false,
  hitCardLightTheme: false,
  hitCardNameTag: 'div',
  labelOverride: '',
  lgSize: 3,
  lazy: false,
  location: {},
  mdSize: 4,
  place: '',
  showFlashDealBar: false,
  sizesOverride: null,
  smSize: 4,
  wishlistId: '',
  wishlistSlugs: [],
  xsSize: 6,
  authenticated: false,
  coverTypeOverride: null,
  adaptiveSize: false,
};

const mapStateToProps = ({
  auth: { _id: userId, authenticated },
  wishlist: { allSlugs: wishlistSlugs },
  cart: { allSlugs: cartSlugs },
  siteTheme,
  userLibrary,
  steamLibrary,
  router: { location },

}, { hit: { name, slug } }) => ({
  userId,
  authenticated,
  wishlistSlugs,
  inCart: cartSlugs.includes(slug),
  siteTheme,
  inLibrary: userLibrary.includes(name),
  inSteamLibrary: steamLibrary.includes(name),
  pathname: location.pathname,
});

export default connect(mapStateToProps)(PopupHitCard);
