import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import { Button, Modal, ModalBody } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';

import { currencyCode } from 'Helpers/Geo/data';
import store from 'Redux/store';
import AddToCartBtn from 'Components/Cards/AddToCartBtn';
import HitCardViewBtn from 'Components/Cards/HitCardViewBtn';
import SpecialFormattedNumber from 'Containers/Utility/Price/SpecialFormattedNumber';
import ProductCover from 'Components/Utility/ProductCover';
import CarouselPaging from 'Components/Carousel/CarouselPaging';
import CarouselArrow from 'Components/Carousel/CarouselArrow';

const HitCardQuickLook = ({ hit, featuredCarousel }) => {
  const [modalOpen, setModalOpen] = useState(false);

  const productId = hit?.product_id;

  useEffect(() => {
    if (modalOpen) {
      store.dispatch({ type: 'frontend/hit-card/QUICKLOOK_OPENED', payload: { productId } });
    }
  }, [modalOpen, productId]);

  if (!hit) return null;

  const toggleModal = () => {
    setModalOpen(prevState => !prevState);
  };

  const {
    name,
    slug,
    drm,
    bundle_covers: bundleCovers,
    tiered,
    display_type: displayType,
    game_total: gameTotal,
    dlc_total: dlcTotal,
    presale,
  } = hit;

  let showDLC = false;
  if (dlcTotal && dlcTotal >= 1) {
    showDLC = true;
  }

  let trackSlidesToShow = 3;
  let trackSlidesToScroll = 3;
  if (bundleCovers && bundleCovers.length < trackSlidesToShow) {
    trackSlidesToShow = bundleCovers.length;
    trackSlidesToScroll = bundleCovers.length;
  }

  const settings = {
    infinite: false,
    prevArrow: <CarouselArrow ariaLabel="Previous" arrowDirection="left" containerClassName="arrow-btn-container" />,
    nextArrow: <CarouselArrow ariaLabel="Next" arrowDirection="right" containerClassName="arrow-btn-container" />,
    slidesToShow: trackSlidesToShow,
    slidesToScroll: trackSlidesToScroll,
    centerMode: false,
    lazyLoad: 'ondemand',
    dots: true,
    accessibility: true,
    customPaging: i => (
      <CarouselPaging
        slideIndex={i}
        slideLength={Array.isArray(bundleCovers) ? bundleCovers.length : 0}
        slidesToShow={trackSlidesToShow}
      />
    ),
  };

  let singleSlideCarousel = false;
  if (bundleCovers.length <= trackSlidesToShow) {
    singleSlideCarousel = true;
  }

  const showBundleCarousel = Array.isArray(bundleCovers)
   && bundleCovers.length > 0 && !bundleCovers.includes(null);

  return (
    <React.Fragment>
      {!featuredCarousel &&
      <div className="quicklook-btn-container">
        <Button color="link" className="quicklook-btn" onClick={toggleModal}>
          <FontAwesomeIcon icon="eye" />
          <FormattedMessage id="quicklook.button.quicklook" defaultMessage="Quick Look" />
        </Button>
      </div>
        }

      {featuredCarousel &&
      <React.Fragment>
        <div className="CarouselPanel__quickLook__title">
          <FormattedMessage
            id="quicklook.title.included.challenger"
            defaultMessage="{total} titles including:"
            values={{
              total: gameTotal + dlcTotal,
            }}
          />
        </div>
        <div className="CarouselPanel__quickLook__buttonContainer">
          <button className="" onClick={toggleModal}>
            <FormattedMessage id="quicklook.button.quicklooktwo" defaultMessage="Quick Look" />
          </button>
        </div>
      </React.Fragment>
        }

      <Modal isOpen={modalOpen} toggle={toggleModal} className="quicklook-modal">
        <Button
          color="link"
          className="close-button"
          onClick={toggleModal}
        >
          <FontAwesomeIcon icon="times" size="lg" title="Close modal" />
        </Button>
        <ModalBody>
          {showBundleCarousel &&
          <div
            className={`quicklook-slider-container ${singleSlideCarousel ? 'single-slide' : ''}`}
          >
            <div className="slider-main-title">
              {name}
            </div>

            {displayType !== 'book-bundle' && displayType !== 'comic-bundle' &&
            <div className="quicklook-key-points">
              <div className="left-slash" />
              <div className="poin-box">
                {hit.type === 'pick-and-mix' &&
                <FormattedMessage
                  id="quicklook.total-games.choose"
                  defaultMessage="Choose from "
                />
                      }
                <FormattedMessage
                  id="quicklook.total-games"
                  defaultMessage="{gameTotal, plural, one {# Game} other {# Games}}"
                  values={{ gameTotal }}
                />
                      &nbsp;
                {showDLC &&
                <FormattedMessage
                  id="quicklook.total-dlc"
                  defaultMessage="+ {dlcTotal} DLC"
                  values={{ dlcTotal }}
                />
                      }
              </div>
              <div className="middle-slash" />
              <div className="poin-box">
                {hit.type !== 'pick-and-mix' &&
                <FormattedMessage
                  id="quicklook.worth-of-games"
                  defaultMessage="{price} worth of games"
                  values={{
                    price: <SpecialFormattedNumber
                      value={hit.fullPrice[currencyCode]}
                      style="currency"
                    />,
                  }}
                />
                      }
                {hit.type === 'pick-and-mix' &&
                <FormattedMessage
                  id="quicklook.pnm-from"
                  defaultMessage="From {price}"
                  values={{
                    price: <SpecialFormattedNumber
                      value={hit.fullPrice[currencyCode]}
                      style="currency"
                    />,
                  }}
                />
                      }
              </div>
              <div className="middle-slash" />
              <div className="poin-box">
                <FormattedMessage
                  id="quicklook.redeem"
                  defaultMessage="Redeem on {validDrm} "
                  values={{
                    validDrm: drm[0],
                  }}
                />
              </div>
              <div className="right-slash" />
            </div>
                }

            {displayType === 'comic-bundle' &&
            <div className="quicklook-key-points">
              <div className="left-slash" />
              <div className="poin-box">

                {hit.type === 'pick-and-mix' &&
                <FormattedMessage
                  id="quicklook.total-games.choose"
                  defaultMessage="Choose from "
                />
                      }
                <FormattedMessage
                  id="quicklook.total-comics"
                  defaultMessage="{gameTotal, plural, one {# Comic} other {# Comics}}"
                  values={{ gameTotal }}
                />
                      &nbsp;
              </div>
              <div className="middle-slash" />
              <div className="poin-box">
                { hit.type !== 'pick-and-mix' &&
                <FormattedMessage
                  id="quicklook.worth-of-comics"
                  defaultMessage="{price} worth of Comics"
                  values={{
                    price: <SpecialFormattedNumber
                      value={hit.fullPrice[currencyCode]}
                      style="currency"
                    />,
                  }}
                />
                  }

                {hit.type === 'pick-and-mix' &&
                <FormattedMessage
                  id="quicklook.pnm-from"
                  defaultMessage="From {price}"
                  values={{
                    price: <SpecialFormattedNumber
                      value={hit.fullPrice[currencyCode]}
                      style="currency"
                    />,
                  }}
                />
                      }
              </div>
              <div className="middle-slash" />
              <div className="poin-box">
                <FormattedMessage
                  id="quicklook.mulidownload"
                  defaultMessage="Multi-format DRM-Free downloads"
                />
              </div>
              <div className="right-slash" />
            </div>
                }

            {displayType === 'book-bundle' &&
            <div className="quicklook-key-points">
              <div className="left-slash" />
              <div className="poin-box">
                {hit.type === 'pick-and-mix' &&
                <FormattedMessage
                  id="quicklook.total-games.choose"
                  defaultMessage="Choose from "
                />
                      }
                <FormattedMessage
                  id="quicklook.total-books"
                  defaultMessage="{gameTotal, plural, one {# eBook} other {# eBooks}}"
                  values={{ gameTotal }}
                />
              </div>
              <div className="middle-slash" />
              <div className="poin-box">

                {hit.type !== 'pick-and-mix' &&
                <FormattedMessage
                  id="quicklook.worth-of-ebooks"
                  defaultMessage="{price} worth of ebooks"
                  values={{
                    price: <SpecialFormattedNumber
                      value={hit.fullPrice[currencyCode]}
                      style="currency"
                    />,
                  }}
                />
                 }

                {hit.type === 'pick-and-mix' &&
                <FormattedMessage
                  id="quicklook.pnm-from"
                  defaultMessage="From {price}"
                  values={{
                    price: <SpecialFormattedNumber
                      value={hit.fullPrice[currencyCode]}
                      style="currency"
                    />,
                  }}
                />
                      }
              </div>
              <div className="middle-slash" />
              <div className="poin-box">
                <FormattedMessage
                  id="quicklook.ebook-delivery"
                  defaultMessage="Multi-format downloads"
                />
              </div>
              <div className="right-slash" />
            </div>
                }

            <Slider {...settings} className="slider-light-theme pb-3">
              {bundleCovers.map((game, i) => {
                const index = i + 1;
                return (
                  <div className="slide-container" key={game.cover + index}>
                    <div className="slide">
                      <ProductCover
                        thumbnail
                        cover={game.cover}
                        alt={`Product cover for ${game.name}`}
                        displayType={game.type}
                      />
                      {game.type === 'dlc' &&
                      <div className="hitCardLabel">
                        <div className="d-flex label-dlc-container">
                          <div className="label-content">
                            <FormattedMessage id="quicklook.dlc" defaultMessage="DLC" />
                          </div>
                          <div className="label-slash" />
                        </div>
                        <div className="label-triangle triangle-dlc" />
                      </div>
                          }
                    </div>
                  </div>
                );
              })}
            </Slider>

            <div className="buttons-container">
              <HitCardViewBtn hit={hit} />

              {!tiered && !presale &&
              <AddToCartBtn quickLook id={productId} modalToggle={toggleModal} slug={slug} />
                  }
            </div>
          </div>
            }
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

HitCardQuickLook.propTypes = {
  hit: PropTypes.shape({}),
  featuredCarousel: PropTypes.bool,
};

HitCardQuickLook.defaultProps = {
  hit: null,
  featuredCarousel: false,
};

export default HitCardQuickLook;
