import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

const HitCardInCart = ({ slug, allSlugs }) => {
  const inCart = allSlugs.includes(slug);

  if (!inCart) return false;

  return (
    <div className="in-cart-label">
      <FormattedMessage id="hitcardf.incart" defaultMessage="In cart" />
    </div>
  );
};

HitCardInCart.propsTypes = {
  slug: PropTypes.string,
  allSlugs: PropTypes.arrayOf(PropTypes.string),
};

HitCardInCart.defaultProps = {
  slug: '',
  allSlugs: [],
};

const mapStateToProps = ({ cart: { allSlugs } }) => ({ allSlugs });

export default connect(mapStateToProps, null)(HitCardInCart);
