import React from 'react';
import PropTypes from 'prop-types';

import HitCardViewBtn from 'Components/Cards/HitCardViewBtn';
import AddToCartBtn from 'Components/Cards/AddToCartBtn';
import HitCardGiftBtn from 'Components/Cards/HitCardGiftBtn';
import HitCardTieredGiftBtn from 'Components/Cards/HitCardTieredGiftBtn';

function HitCardButtons({
  hit,
  noGermanRelease,
  place,
  payWhatYouWant,
  giftButton,
  displayName,
  wishlistId,
  hideViewButton,
  clickFunction,
}) {
  const isPromotion = (hit.type === 'pick-and-mix');

  return (
    <div className="hit-card-buttons-container">
      {!hideViewButton &&
        <HitCardViewBtn hit={hit} place={place} clickFunction={clickFunction} />
      }

      {!hit.hide_discount && !isPromotion && !giftButton &&
        !hit.tiered && !hit.presale && !noGermanRelease && !hit.free_to_play && !payWhatYouWant &&
        <AddToCartBtn
          showInCartLink
          id={hit.product_id}
          slug={hit.slug}
          voucherToAdd={hit.addVoucher}
          place={place}
        />
      }

      {giftButton && !hit.tiered && !noGermanRelease && !hit.presale && !hit.free_to_play &&
        <HitCardGiftBtn displayName={displayName} hit={hit} wishlistId={wishlistId} />
      }

      {giftButton && hit.tiered && !noGermanRelease && !hit.presale && !hit.free_to_play &&
        <HitCardTieredGiftBtn
          displayName={displayName}
          hit={hit}
          wishlistId={wishlistId}
          bundleTiers={hit.bundle_tiers}
        />
      }
    </div>
  );
}

HitCardButtons.propTypes = {
  hit: PropTypes.shape({}),
  noGermanRelease: PropTypes.bool,
  place: PropTypes.string,
  payWhatYouWant: PropTypes.bool,
  giftButton: PropTypes.bool,
  displayName: PropTypes.string,
  wishlistId: PropTypes.string,
  hideViewButton: PropTypes.bool,
  clickFunction: PropTypes.func,
};

HitCardButtons.defaultProps = {
  hit: {},
  noGermanRelease: false,
  place: '',
  payWhatYouWant: false,
  giftButton: false,
  displayName: '',
  wishlistId: '',
  hideViewButton: false,
  clickFunction: () => {},
};

export default HitCardButtons;
