import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Col } from 'reactstrap';

export default function SkeletonHitCard({
  numberOfCards,
  lgSize,
  mdSize,
  smSize,
  xsSize,
  showFlashDealBar,
}) {
  return (
    <React.Fragment>
      {[...Array(numberOfCards).keys()].map(i =>
        <Col lg={lgSize} md={mdSize} sm={smSize} xs={xsSize} key={i + 1} className="fake-card-container">
          <div className={classNames('fake-hit-card', { 'flash-deal': showFlashDealBar })}>
            <div className="responsive-image responsive-image--16by9" />
            <div className="fake-card-strip">
              <div className="card-icons-price-container" />
            </div>
            {showFlashDealBar &&
              <div className="timer-progress-container" />
            }
          </div>
        </Col>,
      )}
    </React.Fragment>
  );
}

SkeletonHitCard.propTypes = {
  numberOfCards: PropTypes.number,
  lgSize: PropTypes.number,
  mdSize: PropTypes.number,
  smSize: PropTypes.number,
  xsSize: PropTypes.number,
  showFlashDealBar: PropTypes.bool,
};

SkeletonHitCard.defaultProps = {
  numberOfCards: 0,
  lgSize: 3,
  mdSize: 4,
  smSize: 4,
  xsSize: 6,
  showFlashDealBar: false,
};
