import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { Button } from 'reactstrap';

import {
  toggleCheckoutModal,
} from 'Redux/ducks/modal';
import store from 'Redux/store';
import HitLinkTo from 'Helpers/Product/HitLinkTo';

// todo remove path and productID, get from hit
export default function HitCardViewBtn({ place, hit, noFauxLink, clickFunction }) {
  const onViewClick = () => {
    if (place === 'Checkout Modal') {
      store.dispatch(toggleCheckoutModal());
      store.dispatch({ type: 'frontend/checkout-modal/VIEWED_FROM_CHECKOUT_MODAL', payload: { productId: hit.product_id || hit._id } });
    }

    if (place === 'Mega Menu') {
      clickFunction();
    }

    if (place === 'BundleEbookAndSoftwareLastChance') {
      store.dispatch({
        type: 'frontend/click/CLICK_INTERACTION',
        payload: { section: 'CheckoutGiftButton', value: HitLinkTo(hit).pathname },
      });
    }
  };

  return (
    <Button
      color="link"
      tag={Link}
      to={HitLinkTo(hit)}
      className={classNames(
        'btn-reset-style',
        { 'faux-block-link__overlay-link': !noFauxLink },
      )}
      onClick={onViewClick}
    >
      <div className="btn btn-primary">
        <FontAwesomeIcon icon="arrow-alt-circle-right" className="mr-1" />
        <div className="btn-text-container">
          <FormattedMessage id="button.view" defaultMessage="View" />
        </div>
      </div>
    </Button>
  );
}

HitCardViewBtn.propTypes = {
  place: PropTypes.string,
  hit: PropTypes.shape({}),
  noFauxLink: PropTypes.bool,
  clickFunction: PropTypes.func,
};

HitCardViewBtn.defaultProps = {
  place: '',
  hit: {},
  noFauxLink: false,
  clickFunction: () => {},
};
