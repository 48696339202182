import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';
import { Button } from 'reactstrap';

import { saveWishlistItem } from 'Redux/ducks/wishlist';
import { updateAnonWishlistItem } from 'Redux/ducks/anon-wishlist';
import store from 'Redux/store';

const HitCardWishlistBtn = ({
  allSlugs,
  productId,
  slug,
  userId,
  hit,
  wishlistLoaded,
  authenticated,
  componentType,
  place,
  saveWishlistItem: _saveWishlistItem,
}) => {
  const { name } = hit;
  const isInWishlist = allSlugs.includes(slug);

  const onClick = () => {
    if (place === 'Checkout Modal') store.dispatch({ type: 'frontend/checkout-modal/ADDED_TO_WISHLIST_FROM_CHECKOUT_MODAL', payload: { productId } });

    if (!userId) return updateAnonWishlistItem({ name, productId, componentType, slug });

    return _saveWishlistItem({
      slug, active: !isInWishlist, name, productId, componentType, type: hit.type,
    });
  };

  if (componentType === 'WishlistCard') {
    return (
      <button
        className="wishlist-card-delete-button btn-reset-style"
        aria-label="Update item in your wishlist"
        disabled={!wishlistLoaded && authenticated}
        onClick={onClick}
      >
        { isInWishlist ?
          <React.Fragment>
            <FontAwesomeIcon icon="trash-alt" className="mr-1" />
            <FormattedMessage id="WishlistCardDeleteBtn.delete" defaultMessage="Delete" />
          </React.Fragment>
          :
          <React.Fragment>
            <FontAwesomeIcon icon={['far', 'heart']} className="mr-1" />
            <FormattedMessage id="WishlistCardDeleteBtn.addtoyourwishlist" defaultMessage="Add to wishlist" />
          </React.Fragment>
        }
      </button>
    );
  }

  if (componentType === 'HitCard' && isInWishlist) {
    return (
      <React.Fragment>
        <div className="card-actions-container">
          <button
            aria-label={isInWishlist ? 'Wishlist Add' : 'Wishlist Remove'}
            disabled={!wishlistLoaded && authenticated}
            className="card-action"
            onClick={onClick}
          >
            <div className="action-text">
              <FormattedMessage id="hitcardwishlistbtn.icon-button.onwishlist" defaultMessage="On wishlist" />
            </div>
          </button>
        </div>
        <div className="wishlist-icon">
          <button
            aria-label={isInWishlist ? 'Wishlist Add' : 'Wishlist Remove'}
            disabled={!wishlistLoaded && authenticated}
            className="card-action"
            onClick={onClick}
          >
            <div className="action-icon">
              <FontAwesomeIcon icon="heart" />
            </div>
          </button>
        </div>
      </React.Fragment>
    );
  }

  return (
    <div className="wishlist-button">
      <Button
        color="link"
        aria-label={isInWishlist ? 'Wishlist Add' : 'Wishlist Remove'}
        disabled={!wishlistLoaded && authenticated}
        className={isInWishlist ? 'wishlist-add' : 'wishlist-remove'}
        onClick={onClick}
      >
        {isInWishlist ?
          <FontAwesomeIcon icon="heart" size="lg" />
          :
          <FontAwesomeIcon icon={['far', 'heart']} size="lg" />
        }
      </Button>
    </div>
  );
};

HitCardWishlistBtn.propTypes = {
  allSlugs: PropTypes.arrayOf(PropTypes.string),
  productId: PropTypes.string,
  saveWishlistItem: PropTypes.func.isRequired,
  userId: PropTypes.string,
  slug: PropTypes.string,
  hit: PropTypes.shape({}),
  wishlistLoaded: PropTypes.bool,
  authenticated: PropTypes.bool,
  place: PropTypes.string,
  componentType: PropTypes.string,
};

HitCardWishlistBtn.defaultProps = {
  allSlugs: [],
  productId: '',
  userId: '',
  slug: '',
  activeItem: {},
  hit: {},
  wishlistLoaded: false,
  authenticated: false,
  place: '',
  componentType: 'HitCard',
};

const mapStateToProps = ({
  auth: { _id: userId, authenticated },
  wishlist: { allSlugs, wishlistLoaded },
}) => ({
  allSlugs,
  userId,
  authenticated,
  wishlistLoaded,
});

export default connect(mapStateToProps, { saveWishlistItem })(HitCardWishlistBtn);
