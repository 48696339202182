import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import get from 'lodash/get';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';
import classNames from 'classnames';
import { Row } from 'reactstrap';

import { countryCode } from 'Helpers/Geo/data';
import HitCardLabel from 'Components/Cards/HitCardLabel';
import Drm from 'Components/Utility/Drm';
import FlashCardProgress from 'Components/FlashSales/FlashCardProgress';
import FluidImg from 'Components/Utility/FluidImg';
import FormatProductName from 'Helpers/Format/FormatProductName';
import HitCard from 'Components/Cards/HitCard';
import HitCardButtons from 'Components/Cards/HitCardButtons';
import HitCardInCart from 'Components/Cards/HitCardInCart';
import HitCardWishlistBtn from 'Components/Cards/HitCardWishlistBtn';
import HitStructuredData from 'Components/Product/Seo/HitStructuredData';
import PriceContainer from 'Containers/Utility/Price/PriceContainer';
import QuickLook from 'Components/Cards/HitCardQuickLook';
import useViewport from 'Hooks/useViewport';

import 'Components/Cards/MegaHitCard.scss';

function MegaHitCard({ hit, lightTheme, hideDesc, megaHitCardCardNameTag }) {
  const { isBelowMd } = useViewport();
  const { locale } = window;
  const {
    addVoucher,
    available_valid_from: validFrom,
    available_valid_until: validUntil,
    best_ever: bestEver,
    bundle_covers: bundleCovers,
    cover,
    discount_percent_override: discountPercentOverride,
    discount_percent: discountPercent,
    display_type: displayType,
    drm,
    flash_sale: flashSale,
    free_to_play: freeToPlay,
    fullPrice,
    giveaway,
    hide_discount: hideDiscount,
    link,
    mystery,
    operating_systems: OS,
    pay_what_you_want: payWhatYouWant,
    presale,
    price,
    product_id: productId,
    release_date: releaseDate,
    sdesc,
    sdescs,
    slug,
    tiered,
    type,
    star_deal: isStarDeal,
  } = hit;

  const name = FormatProductName(hit.name, type);

  const features = get(hit, 'features', []);
  const lowerCaseFeatures = features.map(feature => feature.toLowerCase());

  let freeToPlayTag = false;
  if (includes(lowerCaseFeatures, 'free to play')) {
    freeToPlayTag = true;
  }

  const isPromotion = (type === 'pick-and-mix');

  let noGermanRelease = false;
  if (countryCode === 'DE' && hit.no_release_date) {
    noGermanRelease = true;
  }

  let productLink = `/${type}/${slug}`;
  if (link) productLink = link;

  const TagName = megaHitCardCardNameTag;

  if (isBelowMd) {
    return (
      <Row>
        <HitCard
          hit={hit}
          key={hit.product_id}
          hitCardLightTheme={lightTheme}
          xsSize={12}
          smSize={12}
          mdSize={12}
          lgSize={12}
          showFlashDealBar={flashSale}
        />
      </Row>
    );
  }

  return (
    <div className={classNames('hit-mega-card faux-block-link', { 'card-light-theme': lightTheme })}>
      <HitStructuredData hit={hit} />
      <HitCardLabel
        bestDiscount={bestEver}
        discountPercent={discountPercent}
        displayType={displayType}
        endingDate={validUntil}
        noReleaseDate={hit.no_release_date}
        preorderDate={releaseDate}
        presale={presale}
        slug={slug}
        startingDate={validFrom}
        type={type}
        isStarDeal={isStarDeal}
      />
      <div className="card-content-container">
        <Link to={productLink} className="image-container">
          <div className="responsive-image-island">
            <div className="responsive-image responsive-image--16by9">
              <FluidImg
                ratio16by9
                className="img-force-full"
                src={cover}
                alt={name}
              />
            </div>
          </div>
        </Link>
        <div className="content-container">
          <div className="name-description-container">
            <HitCardInCart slug={slug} />
            <TagName className="product-name h2">
              <Link to={productLink} className="faux-block-link__overlay-link">
                {name}
              </Link>
            </TagName>

            {!hideDesc &&
              <div className="top-product-description">
                {(sdescs ? sdescs[locale] : null) || sdesc}
              </div>
            }

            {flashSale &&
              <FlashCardProgress startingDate={validFrom} endingDate={validUntil} />
            }
          </div>
          <div className="price-icons-container">
            <div className="card-os-icons">
              {OS.map(os =>
                <span key={os}>
                  {os === 'windows' &&
                    <FontAwesomeIcon icon={['fab', 'windows']} size="lg" className="mr-2" />
                  }
                  {os === 'mac' &&
                    <FontAwesomeIcon icon={['fab', 'apple']} size="lg" className="mr-2" />
                  }
                  {os === 'linux' &&
                    <FontAwesomeIcon icon={['fab', 'linux']} size="lg" />
                  }
                </span>,
              )}
            </div>
            {drm &&
              <Drm drmType={drm[0]} colorOverride={lightTheme ? 'dark' : ''} />
            }
            {!presale && !giveaway && !freeToPlay &&
              <PriceContainer
                type={type}
                discountPercent={discountPercent}
                price={price}
                tiered={tiered}
                hideDiscount={hideDiscount}
                slug={slug}
                discountPercentOverride={discountPercentOverride}
                showAsterisk={!!addVoucher}
                fullPrice={fullPrice}
                payWhatYouWant={payWhatYouWant}
              />
            }
          </div>
          <div className="top-product-overlay-container">
            <div className="top-product-overlay">
              {presale && (!freeToPlayTag || hit.free_to_play) && !isPromotion &&
                <HitCardWishlistBtn productId={productId} slug={slug} hit={hit} componentType="MegaHitCard" />
              }
              {!hit.presale && !isPromotion &&
                <HitCardWishlistBtn productId={productId} slug={hit.slug} hit={hit} componentType="MegaHitCard" />
              }
              <div className="overlay-content">
                {!link &&
                  <Link to={`/${type}/${slug}`} className="faux-block-link__overlay-link">
                    <h6>{name}</h6>
                  </Link>
                }
                {link &&
                  <Link to={link} className="faux-block-link__overlay-link">
                    <h6>{name}</h6>
                  </Link>
                }
                <HitCardButtons
                  hit={hit}
                  noGermanRelease={noGermanRelease}
                  payWhatYouWant={payWhatYouWant}
                />
                {hit.type === 'bundle' && !isEmpty(bundleCovers) && !mystery &&
                  <div className="quicklook-container">
                    <QuickLook hit={hit} />
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

MegaHitCard.propTypes = {
  hideDesc: PropTypes.bool,
  hit: PropTypes.shape({}),
  lightTheme: PropTypes.bool,
  megaHitCardCardNameTag: PropTypes.string,
};

MegaHitCard.defaultProps = {
  hideDesc: false,
  hit: {},
  lightTheme: false,
  megaHitCardCardNameTag: 'h3',
};

export default MegaHitCard;
