import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';

import {
  toggleGiftModal,
  toggleLoginModal,
} from 'Redux/ducks/modal';
import store from 'Redux/store';

const HitCardGiftBtn = ({
  displayName,
  hit,
  authenticated,
  wishlistId,
  emailConfirmed,
  authWishlistId,
}) => {
  const onGiftClick = () => {
    if (!authenticated) {
      return store.dispatch(toggleLoginModal());
    }

    if (wishlistId) {
      return store.dispatch(toggleGiftModal({ giftOrder: {}, giftAction: 'wishlist-item', displayName, item: hit, wishlistId, emailConfirmed }));
    }

    return false;
  };

  let skaItem = false;
  let validDrm = '';
  if (hit.drm && hit.drm.length === 1) validDrm = hit.drm[0];

  const skaArray = ['origin', 'epicgames', 'uplay', 'threeds', 'switch'];

  if (skaArray.includes(validDrm)) {
    skaItem = true;
  }

  if (skaItem || (authWishlistId === wishlistId) || hit.giveaway || hit.presale) return false;

  return (
    <Button
      color="primary"
      className="AddToCartBtn"
      onClick={onGiftClick}
    >
      <FontAwesomeIcon icon="gift" className="mr-1" />
      <FormattedMessage id="hitcardgiftbtn.view" defaultMessage="Gift" />
    </Button>
  );
};

HitCardGiftBtn.propTypes = {
  displayName: PropTypes.string,
  hit: PropTypes.shape({}),
  authenticated: PropTypes.bool,
  wishlistId: PropTypes.string,
  emailConfirmed: PropTypes.bool,
  authWishlistId: PropTypes.string,
};

HitCardGiftBtn.defaultProps = {
  displayName: '',
  hit: {},
  authenticated: false,
  wishlistId: '',
  emailConfirmed: false,
  authWishlistId: '',
};

const mapStateToProps = ({
  auth: { authenticated, email_confirmed: emailConfirmed, wishlist_id: authWishlistId },
}) => ({
  authenticated,
  emailConfirmed,
  authWishlistId,
});

const mapDispatchToProps = {
  toggleLoginModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(HitCardGiftBtn);
